import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const FurnituraPage = () => (
  <Layout activeItem="Фурнитура">
    <Seo title="Фурнитура"/>
        <h1>Фурнитура</h1>

        <table class="table table-hover table-sm">
        <thead><tr><th class="text-center" scope="col">№</th><th class="text-center" scope="col">Наименование</th><th class="text-center" scope="col">Описание</th><th class="text-center" scope="col">Цена (руб.)</th></tr></thead><tbody>
        <tr><th  class="text-center text-uppercase table-info" colspan="4">Ручки</th></tr>
        <tr><td>1</td><th>Ручка 26 </th><td>хром (40 шт\уп) кв 130,150мм.</td><th class="text-right">349,65</th></tr>
        <tr><td>2</td><th>Ручка 26 </th><td>бронза (40 шт\уп) кв 130,150мм.</td><th class="text-right">434,70</th></tr>
        <tr><td>3</td><th>Ручка 26 </th><td>золото (40 шт\уп) кв 130,150мм.</td><th class="text-right">434,70</th></tr>
        <tr><td>4</td><th>Ручка 28</th><td>хром, латунь (40 шт/кор)</td><th class="text-right">425,25</th></tr>
        <tr><td>5</td><th>Ручка 93</th><td>хром  (40шт/кор)</td><th class="text-right">434,70</th></tr>
        <tr><td>6</td><th>Ручка КР1</th><td>квадратное основание хром/хром шлифованный </td><th class="text-right">850,50</th></tr>
        <tr><td>7</td><th>Ручка КР2</th><td>квадратное основание хром/хром шлифованный </td><th class="text-right">850,50</th></tr>
        <tr><td>8</td><th>Ручка HP-0203</th><td>INSPEKTOR п/п нержавеющая сталь +накладки цилиндровые </td><th class="text-right">604,80</th></tr>
        <tr><td>9</td><th>Ручка HP-72/1303</th><td>INSPECTOR п/п нержавеющая чталь на планке</td><th class="text-right">585,90</th></tr>
        <tr><td>10</td><th>Ручка  1919C-S</th><td>INSPECTOR п/п с пружиной на планке черная</td><th class="text-right">340,20</th></tr>
        <tr><td>11</td><th>Ручка скоба </th><td>Интекрон со сферой</td><th class="text-right">907,20</th></tr>
        <tr><td>12</td><th>Ручка скоба </th><td>INSPECTOR (25*300мм) хром, матовый хром</td><th class="text-right">1 001,70</th></tr>
        <tr><th  class="text-center text-uppercase table-info" colspan="4">Замки </th></tr>
        <tr><td>13</td><th>Гардиан 1011</th><td>замок врезной сувальдный, с защелкой</td><th class="text-right">880,74</th></tr>
        <tr><td>14</td><th>Гардиан 1201</th><td>замок врезной цилиндровый</td><th class="text-right">383,67</th></tr>
        <tr><td>15</td><th>Гардиан 1211</th><td>замок врезной цилиндровый с защелкой </td><th class="text-right">631,26</th></tr>
        <tr><td>16</td><th>Гардиан 3001</th><td>замок врезной сувальдный </td><th class="text-right">793,80</th></tr>
        <tr><td>17</td><th>Гардиан 3201</th><td>замок врезной цилиндровый</td><th class="text-right">582,12</th></tr>
        <tr><td>18</td><th>Гардиан 3211</th><td>замок врезной цилиндровый с защелкой </td><th class="text-right">667,17</th></tr>
        <tr><td>19</td><th>Гардиан 1001</th><td>замок врезной сувальдный </td><th class="text-right">667,17</th></tr>
        <tr><td>20</td><th>Гардиан 2001</th><td>замок врезной сувальдный </td><th class="text-right">1 341,90</th></tr>
        <tr><td>21</td><th>Гардиан 7114</th><td>замок врезной сувальдный с защелкой и перекодировкой с торца</td><th class="text-right">6 202,98</th></tr>
        <tr><td>22</td><th>Гардиан 7216</th><td>замок врезной цилиндровый с защелкой и редукторным механизмом</td><th class="text-right">3 381,21</th></tr>
        <tr><td>23</td><th>Гардиан 7514</th><td>замок врезной комбинированны с защелкой м перекодировкой с торца</td><th class="text-right">6 868,26</th></tr>
        <tr><td>24</td><th>Гардиан 7516</th><td>замок врезной двухсистемный цилиндровый с защелкой и редукторным механизмом </td><th class="text-right">4 120,20</th></tr>
        <tr><td>25</td><th>Гардиан 2002</th><td>замок врезной сувальдный </td><th class="text-right">2 328,48</th></tr>
        <tr><td>26</td><th>Гардиан 2112</th><td>замок врезной сувальдный с защелкой </td><th class="text-right">2 632,77</th></tr>
        <tr><td>27</td><th>Гардиан 2512</th><td>замок врезной комбинированный с защелкой</td><th class="text-right">3 284,82</th></tr>
        <tr><td>28</td><th>Гардиан 2212</th><td>замок врезной цилиндровый с защелкой </td><th class="text-right">1 733,13</th></tr>
        <tr><th  class="text-center text-uppercase table-info" colspan="4">Цилиндры</th></tr>
        <tr><td>29</td><th>INSPECTOR 85мм</th><td>(50*10*25) СР шток хром 5 перф.кл.</td><th class="text-right">661,50</th></tr>
        <tr><td>30</td><th>INSPECTOR 95мм</th><td>(60*10*25) СР шток хром 5 перф.кл.</td><th class="text-right">699,30</th></tr>
        <tr><td>31</td><th>INSPECTOR 70мм</th><td>(35*35) Цам СР кл-верт хром 5 англ.кл.</td><th class="text-right">228,69</th></tr>
        </tbody></table>

  </Layout>
)

export default FurnituraPage
